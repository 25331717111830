@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
	scrollbar-color: #01AEF0 #FFFFFF;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

.checkout_contact_body_title a {
  color: #01AEF0 !important;
}

button {
  cursor: pointer;
}
.sm-txt{
  font-weight: 500;
    line-height: 1.33;
    font-size: 12px;
    letter-spacing: .96px;
    text-align: left;
    color: #414042;
 
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: poppins;
}

ul {
  list-style: none;
}

.container {
  margin: 0 auto;
  width: 80vw;
}

.slide-title h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.myAppBar {
  background-color: #333;
}

header {
  width: 100%;
  padding-top: 0px;
  box-shadow: -3px -15px 20px 0px;
}

.header-logo {
  margin-right: 21px;
}

.myTitle {
  flex-grow: 1;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  text-align: center;
}

.header-ul {
  list-style: none;
}

.search-feild {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 25%;
  right: 2%;
  width: 5%;
}

header {
  padding-top: 0.52vw;
  padding-bottom: 0.88vw;
}
.product_main_img{
  background-repeat: no-repeat;
}
.product_detail_wrapper {
  margin-bottom: 10vw;
}

.search-results a {
  color: #555;
}
.slick-track{
      transform: translate3d(0vw, 0px, 0px);
}
.search-feild input::placeholder {
  color: #BBB;
  font-size: 0.93vw;
  font-weight: 400;
  font-family: 'Poppins';
}

.search-feild input {
  background-color: rgba(1, 174, 240, 0.07);
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  width: 19.00vw;
  padding: 1.8%;
}

.search-container {
  position: relative;
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #edf9fe;
  border: 1px solid #ccc;
  z-index: 1;
  display: block;
  /* Ensure it's visible */
  max-height: 200px;
  /* Set a maximum height for scrollbar */
  overflow-y: auto;
  /* Add a scrollbar when content exceeds max-height */
  min-height: 50px;
  /* Set a minimum height */
}

.search-results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  /* Add this to make items clickable */
}

.search-results li:last-child {
  border-bottom: none;
}
.product_main_img{
  overflow: hidden;
}
.de_pop{
      display: flex;
    align-items: center;
    align-items: center;
    justify-content: space-between;
}

.de_pop svg{
  cursor: pointer;
}

.header-logo img {
  width: 17.03vw;
}

.header-ul li {
  color: #01AEF0;
  font-family: Poppins;
  font-size: 0.93vw;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.profile-icon {
  border-radius: 22px;
  background: rgba(1, 174, 240, 0.07);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-icon img {
  width: 62%;
}

.cart-icon {
  border-radius: 22px;
  background: rgba(1, 174, 240, 0.07);
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

span.cart-badge {
  position: absolute;
  bottom: 2px;
  font-size: 9px;
  color: #f00;
}

.cart-icon img {
  width: 80%;
}

.disable-button {
  pointer-events: none;
  opacity: 0.5;
  /* You can adjust the opacity to indicate it's disabled */
}

.call-icon {
  width: 1.04vw;
  margin-right: 0.26vw;
}

.MuiStack-root {
  width: 400px !important;
}

/* category header */
.category-header-wrapper {
  width: 100%;
  background: #01AEF0;
   /* padding-top: 5px; 
   padding-bottom: 5px;  */
}

.cate-header-ul {
  list-style: none;
}

.cate-header-ul li a {
  text-decoration: none;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.9em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5em;
}

.home_page_slider img {
	width: 100%;
	height: auto;
  opacity: 1;
  animation: slideText 0.5s linear;
  /* transform: translateX(0%); */
}
@keyframes slideText {
  0% {
    opacity: translateX(0);
  }
  100% {
    opacity: translateX(1);
  }
}

.slider-content {
  position: relative;
  height: 100%;
}

.h-100 {
  height: 100%;
}

.slider-head {
  position: absolute;
  top: 40%;
}

.slider-head h3 {
  color: #DEDEDE;
  font-family: Poppins;
  font-size: 1.04vw;
  font-weight: 500;
  margin-bottom: 20px;
}

.slider-head h2 {
  color: #FFF;
  font-family: Poppins;
  font-size: 4.37vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4.68vw;
  margin-bottom: 50px;
}

.button_transparent {
  background: transparent;
  border: 0;
}

.bg_transparent {
  background: transparent;
  border: 0;
}

.slider-head button {
  color: #01AEF0;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  background-color: #fff;
  border: none;
  border-radius: 40px;
  width: 9.01vw;
  height: 2.60vw;

}

.discover-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.discover-list {
  display: block;
  /* column-gap: 2.08vw; */
}

.discover-box {
  width: 30.36vw !important;
  display: flex !important;
  background: rgba(215, 53, 65, 0.1);
  border-radius: 14px;
  padding: 2.08vw 1.4vw;
  height: 16.66vw;
  flex-grow: 0;
  flex-shrink: 0;
}

.discover-img img {
  width: 13.08vw;
  height: 13.08vw;
  mix-blend-mode: multiply;
  display: block;
}

.discover-img {
  width: 50%;
}

.discover-area-wrapper {
  overflow: hidden;
}

.discover-area-wrapper h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.87vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2.3vw;
  margin-top: 6.20vw;
}

.discover-content h4 {
  color: #555;
  font-family: Poppins;
  font-size: 0.72VW;
  font-weight: 400;
}

.discover-area-wrapper {
  margin-bottom: 5.26vw;
}

.discover-content h3 {
  color: #555;
  font-family: Poppins;
  font-size: 1.25VW;
  font-weight: 600;
}

.discover-content button {
  color: #555;
  font-family: Poppins;
  font-size: 0.72vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  height: 2.60vw;
  width: 6.77vw;
  border: none;
}

.explore-box-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.71vw;
  row-gap: 1.71vw;
}

.explore-head-img img {
  width: 2.34vw;
  height: 2.34vw;
}

.explore-box-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.96vw;
}

.explore-box-content {
  margin-bottom: 4.47vw;
}

.explore-head-img {
  background-color: #EEF2FF;
  width: 3.95vw;
  height: 3.95vw;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explore-head-img img {
  mix-blend-mode: multiply;
}

.explore-title h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.87vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 5.2vw;
  padding-bottom: 1.92vw;
}

.explore-nav {
  width: fit-content;
  margin: 0 auto;
  border-radius: 40px;
  background: #FFF;
  box-shadow: 0px 2px 8px 3px rgba(0, 0, 0, 0.10);
  margin-bottom: 3.17vw;
}

/* .header-ul ul li:nth-child(1){
  margin-left: 2.55vw;
} */

.explore-nav-menu li {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding-top: 0.52vw;
  padding-bottom: 0.52vw;
  align-items: center;
  padding-left: 1.04vw;
  padding-right: 1.04vw;
  border-radius: 40px;

}

.explore-nav-menu li.active {
  background-color: #01AEF0;
  color: #fff;
}

.explore-nav-menu li img {
  width: 0.93vw;
  height: 0.93vw;
  margin-right: 0.52vw;
}

.explore-nav-menu {
  list-style: none;
}

.header-ul .call-li {
  margin-left: 2.55vw;
  margin-right: 2.17vw;
}

.explore-head-title h3 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.explore-content-title {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.explore-content-title2 h3 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.45vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.explore-content-title1 h4 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.explore-wrapper {
  background-color: rgba(3, 128, 176, 0.05);
  padding-bottom: 5.2vw;
}

.explore-box {
  position: relative;
}

.profile-icon {
  margin-right: 0.57vw;
}

.expo-img-abs {
  position: absolute;
  bottom: 1.04vw;
  right: 1.04vw;
}

.expo-img-abs img {
  width: 5.67vw;
  height: 9.11vw;
}

.explore-box {
  border-radius: 14px;
  background: #FFF;
  width: 23.90vw;
  padding-top: 3.8vw;
  padding-left: 2.61vw;
  padding-right: 2.61vw;
  padding-bottom: 3.17vw;
  flex-grow: 0;
  flex-shrink: 0;
}

.arrivals_title {
  margin-bottom: 2.39vw;
}

.arrivals_content_title h3 {
  color: #01AEF0;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.arrivals_content_title h4 {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.arrivals_wrapper {
  margin-bottom: 4.84vw;
}

.arrivals_img {
  border-radius: 14px;
  /* background: rgba(3, 128, 176, 0.05); */
  border: 1px solid rgba(3, 128, 176, 0.1);
}

.arrivals_color_list {
  display: flex;
  column-gap: 0.36vw;
  margin-top: 1.04vw;
  margin-bottom: 0.72vw;
}

.arrivals_img img {
  width: 13.69vw;
  mix-blend-mode: multiply;
  margin: 0 auto;
  display: block;
}

.arrivals_box {
  width: 17.34vw;
}

.arrivals_list {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  column-gap: 1.92VW;
}

.arrivals_color_box {
  padding: 0.10vw;
  border-radius: 20px;
}

.arrivals_color_box.active {
  border: 1px solid #252525;
}

.arrivals_color {
  width: 2.08vw;
  height: 1.09vw;
  border-radius: 20px;
  background: #45573D;
}

.trend_wrapper .container {
  display: block;
}

.trend_wrapper {
  padding-top: 4.58vw;
  padding-bottom: 7.34vw;
}

.trend_wrapper .arrivals_list {
  flex-wrap: wrap;
  row-gap: 2.6vw;
}

.trend_para {
  color: #555;
  font-family: Poppins;
  font-size: 1.35vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.trends_menu1_btn {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.30);
  align-items: center;
  background-color: #fff;
  padding: 0.52vw 1.53vw;
}

.trends_menu1_btn img {
  width: 0.93vw;
  margin-right: 0.62vw;
}

.trens_menu_part1 {
  display: flex;
  column-gap: 1.56vw;
}

.trends_menu_list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.trends_menu2_btn {
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 40px;
  background: #01AEF0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.52vw 1.3vw;
}

.trends_menu2_btn img {
  width: 0.83vw;
}

.trends_menu2_btn img:nth-child(1) {
  margin-right: 0.57vw;
}

.trends_menu2_btn img:nth-child(2) {
  margin-left: 0.57vw;
}

.trend_wrapper hr {
  opacity: 0.15000000596046448;
  background: #252525;
  margin-top: 3.12vw;
  margin-bottom: 3.12vw;
}

.trend_menu {
  margin-top: 2.23vw;
}

.trend_bottom_btn {
  text-align: center;
  margin-top: 3.33vw;
}

.trend_bottom_btn button,
.trend_bottom_btn a {
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background: #01AEF0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  border: none;
  padding: 0.67vw 1.74vw;
  text-align: center;
}

.spe_offer {
  background-color: rgba(114, 185, 99, 0.1);
  display: flex;
  height: 27.03vw;
  border-radius: 35px;
  margin-bottom: 5.2vw;
  column-gap: 3.9vw;
}

.spe_offer_img img {
  width: 34.89vw;
  height: 34.89vw;
  margin-top: -7.9vw;
}

.spe_offer_logo {
  margin-top: 4.94vw;
}

.spe_offer_logo img {
  width: 11.35vw;
  height: 2.08vw;
  mix-blend-mode: multiply;
  margin-bottom: 0.88vw;
}

.spe_offer_title h3 {
  color: #000;
  font-family: Poppins;
  font-size: 3.125vw;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0.88vw;
  line-height: 3.64vw;
}

.spe_offer_para p {
  color: #555;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  margin-bottom: 1.03vw;
  font-weight: 400;
  line-height: normal;
}

.spe_offer_button button {
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background: #01AEF0;
  width: 9.01vw;
  height: 2.6vw;
  border: none;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}

.expert-main-img img {
  width: 13.54vw;
  display: block;
  margin: 0 auto;
  mix-blend-mode: multiply;
}

.expert-main-img {
  border-radius: 20px;
  background: #F0F8FB;
  margin-bottom: 0.67vw;
  height: 14.84vw;
  display: flex;
  align-items: end;
}

.expert-box {
  width: 23.85vw;
}

.expert-imgs .expert-img1 img,
.expert-imgs .expert-img2 img,
.expert-imgs .expert-img3 img {
  width: 5.31vw;
  display: block;
  mix-blend-mode: multiply;
}

.expert-imgs .expert-img1,
.expert-imgs .expert-img2,
.expert-imgs .expert-img3 {
  border-radius: 20px;
  background: #F0F8FB;
  width: 7.5vw;
  height: 6.66vw;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expert-wrapper {
  margin-bottom: 5.2vw;
}

.expert_main_head {
  margin-bottom: 2.6vw;
}

.expert-imgs {
  display: flex;
  column-gap: 0.67vw;
  margin-bottom: 1.14vw;
}

.expert-title H3 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.14vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.expert-detail {
  display: flex;
  margin-top: 0.57vw;
}

.expert-color h4 {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expert-rating h4 {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.expert-rating img {
  height: 0.78vw;
  width: 0.78vw;
  margin-right: 0.26vw;
}

.expert-rating {
  display: flex;
  align-items: center;
}

.expert-detail hr {
  margin-left: 0.72vw;
  margin-right: 0.72vw;
}

.expert-list {
  display: flex;
  column-gap: 1.97vw;
}

.footer-wrapper hr {
  opacity: 0.1;
  background: #252525;
  border: 0.025vw solid #252525;
}

.footer {
  padding-top: 3.95vw;
  padding-bottom: 2.91vw;
}

.footer-logo img {
  width: 17.03vw;
}

.footer-follow .footer-icon img {
  width: 1.51vw;
}

.footer-detail p {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-our-company h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer-contact h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer-follow h2 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer-bebrand-detail {
  margin-right: 10.93vw;
}

.footer-our-company {
  margin-right: 8.75vw;
}

.footer-contact {
  margin-right: 3.75vw;
}

.footer-icons {
  display: flex;
  column-gap: 1.17vw;
}

.footer-our-company ul li {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.66vw;
}

.footer-contact ul li {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.66vw;
}

.footer-logo {
  margin-bottom: 1.04vw;
}

.footer h2 {
  margin-bottom: 1.82vw;
}

.bottom_footer p {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom_footer p img {
  width: 0.83vw;
}

.bottom_footer {
  padding-top: 1.04vw;
  padding-bottom: 1.56vw;
}

.category_title {
  margin-top: 5.2vw;
  margin-bottom: 2.23vw;
}

.category_box {
  width: 17.37vw;
}

.category_box_img img {
  width: 12.2vw;
  mix-blend-mode: multiply;
  display: block;
  margin: 0 auto;
}

.category_box_img {
  border-radius: 14px;
  /* background: rgba(3, 128, 176, 0.05); */
  border: 1px solid rgba(3, 128, 176, 0.1);
  padding-top: 4.32vw;
  padding-bottom: 2.20vw;
  padding-left: 2.43vw;
  padding-right: 2.43vw;
  margin-bottom: 1.17vw;
}

.category_box_title h3 {
  color: #252525;
  font-size: 1.04vw;
  font-weight: 600;
  text-align: center;
}

.category_box_subtitle h4 {
  color: #555;
  font-size: 0.83vw;
  font-weight: 400;
  text-align: center;
}

.category_list {
  display: flex;
  column-gap: 1.99vw;
  flex-wrap: wrap;
  row-gap: 3.28vw;
}

.category_listing_wrapper {
  padding-bottom: 5.05vw;
  position: relative;
}

.collection_title {
  margin-top: 2.20vw;
  margin-bottom: 0.83vw;
}

.collection_para {
  color: #555;
  font-family: Poppins;
  font-size: 0.93vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 2.60vw;
}

.trends_menu1_btn:hover {
  border-radius: 30px;
  border: 1px solid #01AEF0;
  background: #E6F7FE;
}

.products_list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.97vw;
  row-gap: 4.73vw;
  margin-top: 2.23vw;
}

.products_list>div {
  flex-grow: 0;
  flex-shrink: 0;
}

.product-tag {
  display: flex;
  column-gap: 0.31vw;
  background-color: #fff;
  width: 4.58vw;
  height: 1.40vw;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.product-tag h5 {
  color: #666;
  font-family: Poppins;
  font-size: 0.72vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.product-tag img {
  width: 0.70vw;
}

.product-tag img {
  margin: 0;
}

.product_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.25vw 1.35vw 0.88vw 1.04vw;
}

.product_save {
  background-color: #fff;
  border-radius: 50%;
  width: 1.97vw;
  fill: #FFF;
  height: 1.97vw;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.05));
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.product_save img {
  width: 1.45vw;
}

.arrivals_img {
  padding-bottom: 2.18vw;
}

.arrivals_content {
  padding-left: 1.09vw;
}

.trend_page_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trend_pagination {
  display: flex;
  align-items: center;
  column-gap: 0.52vw;
}

.trend_pagination a {
  color: #555;
  font-family: Poppins;
  font-size: 1.04vw;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6vw;
  height: 2.6vw;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.collection_wrapper {
  padding-bottom: 5.2vw;
}

.ex_ch_title {
  margin-bottom: 2.6vw;
}

.pro_expert_choice {
  padding-bottom: 7.87vw;
}

.product_img {
  width: 35vw;
  flex-shrink: 0;
  flex-grow: 0;
}

.product_detail {
  display: flex;
  column-gap: 2.44vw;
  padding-top: 2.2vw;
}

.product_name h2 {
  color: #252525;
  font-size: 1.87vw;
  font-weight: 600;
  margin-bottom: 0.31vw;
}

.product_color_part h4 {
  color: #555;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 400;
}

.product_color_part span {
  color: #252525;
  font-weight: 600;

}

.expert-detail.pro_detail {
  margin-top: 0;
  margin-bottom: 0.78vw;
}

.product_color_part h4 {
  margin-bottom: 0.78vw;
}

.arrivals_color.pro_detail {
  width: 3.85vw;
  height: 2.03vw;
}

.arrivals_color_list.pro_detail {
  margin-top: 0;
  margin-bottom: 1.51vw;
}

.product_size h5 {
  color: #555;
  font-size: 0.83vw;
  font-weight: 400;
  margin-bottom: 1.14vw;
}

.product_size h5 span {
  color: #252525;
  font-weight: 600;
}

.product_size_box {
  display: flex;
  column-gap: 1.14vw;
  margin-bottom: 2.6vw;
}

.product_size_box h6 {
  color: #555;
  font-size: 0.98vw;
  font-weight: 500;
}

.product_detail_content {
  width: 41vw;
}

.product_size_box div {
  width: 3.85vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.03vw;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.product_quote_n_requested_title {
  display: flex;
  column-gap: 6.04vw;
  padding-top: 1.14vw;
  /* padding-bottom: 1.14vw; */
}

.product_quote_n_requested_title h2 {
  color: #252525;
  font-size: 1.35vw;
  font-weight: 400;
}

.product_quote_n_requested_content {
  padding-top: 0;
  padding-bottom: 1.35vw;
}

.product_quote1,
.product_quote2,
.product_quote3,
.product_quote4 {
  display: flex;
  column-gap: 6.19vw;
  margin-bottom: 1.02vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.product_quote1 h3,
.product_quote2 h3,
.product_quote3 h3,
.product_quote4 h3 {
  color: #252525;
  font-size: 0.83vw;
  font-weight: 600;
  margin-bottom: 0.72vw;
}

.product_quote_quantity select,
.product_quote_base select,
.product_quote_prited select,
.product_quote_carry_bag select {
  width: 13.95vw;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  height: 2.03vw;
  padding-left: 1.35vw;
  padding-top: 0.46vw;
  padding-bottom: 0.46vw;
  padding-right: 1.45vw;
}

.product_quote3.artwork {
  display: block;
}

.product_quote3.artwork .product_artwork {
  display: flex;
  column-gap: 6.19vw;
}

.product_artwork1,
.product_artwork2 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.72vw;
  font-weight: 400;
  width: 13.95vw;
  height: 2.03vw;
  display: flex;
  align-items: center;
  padding-left: 1.35vw;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.product_quote4.shipping {
  display: block;
}

.product_shipping_types {
  display: flex;
  column-gap: 0.78vw;
}

.product_shipping_type1,
.product_shipping_type2 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.72vw;
  font-weight: 400;
  width: 6.56vw;
  height: 2.03vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.trend_bottom_btn.pro_detail {
  margin-top: 1.56vw;
  width: 11.30;
  margin-bottom: 0.83vw;
}

.pro_line hr {
  transform: rotate(180deg);
  width: 8.95vw;
}

.pro_line {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #C3C3C3;
  font-family: Poppins;
  font-size: 0.62vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0.78vw;
}

.pro_line span {
  width: 3.33vw;
  text-align: center;
}

.pro_detail_bott_btm {
  display: flex;
  column-gap: 6.09vw;
  padding-left: 2.29vw;
}

.hr_pro {
  margin-top: 0.2vw;
  margin-bottom: 2vw;
}

.bottom_check_detail {
  display: flex;
  column-gap: 1.25vw;
  /* padding-top: 0.72vw; */
  padding-bottom: 3.17vw;
}

.free_shipping,
.national_shipping {
  width: 18.38vw;
  height: 7.39vw;
  border-radius: 14px;
  background: rgba(3, 128, 176, 0.05);
  padding: 1.3vw;
}

.free_shipping img,
.national_shipping img {
  width: 1.56vw;
  height: 1.56vw;
}

.free_shipping h4,
.national_shipping h4 {
  color: #252525;
  font-size: 0.83vw;
  font-weight: 500;
}

.free_shipping h5,
.national_shipping h5 {
  color: #555;
  font-size: 0.83vw;
  font-weight: 400;
}

.product_reviews h3 img {
  width: 0.98vw;
  margin-right: 0.57vw;
}

.product_reviews h3 {
  color: #252525;
  font-size: 1.04vw;
  font-weight: 600;
}

.product_reviews {
  padding-top: 3.59vw;
  padding-bottom: 5.2vw;
}

.review_item {
  width: 33.75vw;
}

.review_star {
  display: flex;
  column-gap: 0.36vw;
}

.review_star img {
  width: 0.98vw;
}

.review_head {
  display: flex;
  align-items: center;
  column-gap: 19.58vw;
}

.review_head_detail h4 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.93vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.review_head_detail h5 {
  color: #555;
  font-family: Poppins;
  font-size: 0.72vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.review_para p {
  color: #555;
  font-size: 0.93vw;
  font-weight: 500;
  margin-top: 2.13vw;
}

.review_list {
  padding-top: 1.614vw;
  display: flex;
  flex-wrap: wrap;
  column-gap: 5.6vw;
  row-gap: 3.125vw;
}

.also_pur_title {
  margin-bottom: 2.39vw;
}

.also_pur_list .product-tag {
  opacity: 0;
}

.also_pur_list {
  display: flex;
  column-gap: 1.99vw;
  margin-bottom: 4.39vw;
}

.product_main_img {
  border-radius: 14px;
  /* background: rgba(3, 128, 176, 0.05); */
  margin-bottom: 2.29vw;
  width: 35vw;
  height: 35.78vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(3, 128, 176, .1);
}

.product_main_img img {
  mix-blend-mode: multiply;
  width: 25.31vw;
  display: block;
}

.product_option_img {
  display: flex;
  column-gap: 2.13vw;
}

.product_option1_img,
.product_option2_img {
  border-radius: 14px;
  /* background: rgba(3, 128, 176, 0.05); */
}

.product_option1_img img,
.product_option2_img img {
  mix-blend-mode: multiply;
  width: 11.45vw;
}

.cart_list {
  width: 75.52vw;
}

.cart_item {
  /* width: 41.66vw; */
  display: flex;
}

.cart_img {
  width: 6.9vw;
  height: 7.55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: rgba(3, 128, 176, 0.05);
  margin-right: 1.55vw;
}

.cart_img img {
  width: 5vw;
  display: block;
  mix-blend-mode: multiply;
}

.cart_detail {
  display: flex;
  align-items: center; 
  justify-content: space-between;
  column-gap: 1vw;
}

.cart_color_size img {
  width: 0.8vw;
}

.cart_count {
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: flex-end; 
  margin-right: 4.35vw;
}

.cart_title {
  /* margin-right: 7.55vw; */
}

.cart_info {
  width: 100%;
}

.cart_info1 {
  margin-bottom: 1.3vw;
}
.review_box {
  margin-top: 1vw;
  display: flex;
  gap: 9px;
}
.review_box img {
  cursor: pointer;
}
.reviews-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 3 columns */
  gap: 25px;
 
}

.reviews-box {
  background-color: rgba(3, 128, 176, 0.05);
  
  border-radius: 10px;
  padding: 15px;

}
.txt_rev {
  margin-top: 12px;
  font-family: poppins;
    font-size: 0.83vw;
    color: rgb(85, 85, 85);
    font-weight: 400;
}
.txt_read_more {
  font-family: poppins;
  font-size: 0.83vw;
  color: rgb(85, 85, 85);
  font-weight: 700;
}

.user-info {
  display: flex;
 gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.user-name {
  font-family: poppins;
  font-size: 18px;
  font-weight: 700;
}

.review-date {
  font-size: 14px;
  color: #888;
}

.rating {
  font-size: 16px;
  color: #ffcc00;
  margin-bottom: 10px;
}

.review-text {
  font-size: 16px;
  line-height: 1.4;
  color: #333;
}


.cart_info2_bt1 {
  margin-right: 1vw;
}

.cart_info2_bt2 {
  /* margin-right: 15.04vw; */
}

.cart_title {
  flex: 1;
}

.cart_title h3 {
  color: #252525;
  font-size: 1vw;
  font-weight: 600;
}

.cart_color_size span {
  color: #555;
  font-size: 0.83vw;
  font-weight: 400;
}

.cart_count h5 {
  /* margin-left: 1.35vw; */
  margin-right: 1.35vw;
  color: #252525;
  font-size: 0.89vw;
  font-weight: 600;
}

.cart_count.checkout h4 {
  margin-left: 0vw;
}

.cart_count button {
  width: 1.94vw;
  height: 1.94vw;
  border-radius: 50%;
  font-size: 1.53vw;
  font-weight: 100;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 1);
  background-color: #fff;
}

.cart_price {
  border-radius: 10px;
  border: 1px solid #01AEF0;
  width: fit-content;
  padding: 8px;
  height: 1.95vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_icn {
  padding: 5px 11px;
  border-radius: 50%;
  background-color: #01AEF0;
  color: white;

}

.cart_price h5 {
  color: #01AEF0;
  font-size: 0.83vw;
  font-weight: 600;
}

.cart_info2_bt1 img {
  width: 0.75vw;
  margin-right: 0.35vw;
}

.cart_info2_bt2 img {
  width: 0.65vw;
  margin-right: 0.35vw;
}

.cart_info2_bt3 img {
  width: 2vw;
  margin-right: vw;
}

.cart_info2 {
  display: flex;
  justify-content: space-between;
}

.left_side_button {
  display: flex;
}

input#file-upload-button {
  border-radius: 10px;
  border: 1px solid #01AEF0;
  background-color: #fff;
}

.cart_info2 button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart_info2_bt1 {
  width: 8.65vw;
  height: 2vw;
  color: #555;
  font-family: Poppins;
  font-size: 0.8vw;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.cart_info2_bt2 {
  width: 7.8vw;
  height: 1.85vw;
  color: #555;
  font-family: Poppins;
  font-size: 0.8vw;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.cart_info2_bt3 {
  width: 6.05vw;
  height: 1.85vw;
  color: #01AEF0;
  font-family: Poppins;
  font-size: 0.8vw;
  font-weight: 500;
  border-radius: 30px;
  border: 1px solid #01AEF0;
  background-color: #fff;
  justify-content: flex-start !important;
}
.artwork_icons {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.disabled-button {
  /* Add your styles for disabled button here */
  opacity: 0.5; /* Example: reduce opacity for a visual indication */
  cursor: not-allowed; /* Example: change cursor style */
}

.cart_color_size span {
  display: flex;
}

.cart_color_size span hr {
  margin-right: 0.83vw;
  margin-left: 1.3vw;
}

.cart_color_size span img {
  margin-right: 0.6vw;
}

.cart_item {
  margin-top: 3.05vw;
}

.cart_item_space {
  margin-top: 3.59vw;
  margin-bottom: 3.59vw;
}

.cart_list {
  border-right: 1px solid rgba(37, 37, 37, 0.15);
}

.cart_wrap {
  display: flex;
}

.cart_total_list h3 {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cart_total_list h4 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cart_count1 {
  display: flex;
  justify-content: space-between;
}

.cart_total {
  margin-top: 3.17vw;
  margin-left: 5.15vw;
  width: 23.54vw;
}

.cart_total .cart_title h3 {
  margin-bottom: 1.14vw;
}

.cart_total_hr {
  margin-top: 1.24vw;
  margin-bottom: 1.56vw;
}

.cart_total button {
  margin-top: 1.71vw;
  margin-bottom: 1.56vw;
  width: 23.54vw;
  height: 2.60vw;
  border-radius: 40px;
  background: #01AEF0;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  border: none;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}

.cart_total p {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cart_total p a {
  color: #252525;
}

.cart_wrap {
  margin-bottom: 5.41vw;
}

.empty_cart_message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout_wrapper {
  display: flex;
  column-gap: 3.08vw;
}

.checkout_detail_form {
  width: 44.27vw;
  flex-grow: 0;
  flex-shrink: 0;
}

.checkout_contact_form {
  width: 41.25vw;
}

.chekout_contact_icon img {
  width: 2.08vw;
}

.checkout_contact_head {
  display: flex;
  align-items: center;
}

.checkout_shipping_form .checkout_contact_head {
  justify-content: space-between;
}

.chekout_contact_icon {
  margin-right: 1vw;
}

.chekout_contact_title {
  margin-right: 13.95vw;
}

.checkout_contact_form {
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkout_shipping_form_inside {
  margin-top: 2vw;
}

.shipping_address_item {
  display: flex;
  justify-content: space-between;
  margin: 0.8vw 0;
}

.shipping_address_item button {
  border-radius: 10px;
  background: rgba(1, 174, 240, 0.20);
  border: none;
  width: 7.13vw;
  height: 2.6vw;
  color: #000;
}

.form_head {
  margin-top: 1.82vw;
  margin-left: -1.97vw;
  margin-bottom: 2.55vw;
  margin-right: -2.08vw;
}

.chekout_contact_title h3 {
  color: #252525;
  font-family: Poppins;
  font-size: 1.04vw;
  font-weight: 600;
}

.chekout_contact_title h4 {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkout_contact_form,
.checkout_shipping_form,
.checkout_payment_form {
  padding: 1.30vw 2.08vw 0 1.97vw;
}

.chekout_contact_change button {
  border-radius: 10px;
  border: none;
  width: 9.13vw;
  padding: 0vw 1vw;
  height: 2.6vw;
}

.space-between-checkout {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkout_contact_body_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkout_contact_body_title h3 {
  color: #252525;
  font-size: 1.04vw;
  font-weight: 600;
}

.checkout_contact_body_title h4 {
  color: #555;
  font-size: 0.83vw;
  font-weight: 500;
}

.checkout_form_tel h3,
.checkout_form_mail h3 {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  margin-top: 1.87vw;
}

.checkout_form_mail span {
  color: #555;
  font-family: Poppins;
  font-size: 0.72vw;
  font-weight: 500;
}

.checkout_form_mail input[type="checkbox"] {
  width: 0.72vw;
  margin: 0;
  margin-right: 0.72vw;
}

.checkbox_mail {
  display: flex;
  align-items: center;
}

.checkout_form_tel input,
select,
.checkout_form_mail input,
.checkout_form_tel textarea {
  width: 29.01vw;
  height: 2.60vw;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 0.67vw;
  padding: 0 0.5vw;
}

.checkout_form_tel textarea {
  height: 7vw;
  padding: 9px;
}

.save_btn_checkout {
  width: 17.08vw;
  height: 2.6vw;
  border-radius: 40px;
  background: #01AEF0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  color: #FFF;
  font-family: Poppins;
  border: none;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cancel_btn_checkout {
  background-color: #fff;
  border: none;
  margin-left: 3.54vw;
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  margin-bottom: 3.64vw;
}

.checkout_form_mail {
  padding-bottom: 2.68vw;
}

.checkout_order_summary .cart_list {
  width: 28.22vw;
}

.checkout_order_summary .cart_item {
  width: 28.22vw;
}

.cart_info2_bt1.checkout {
  width: 6.04vw;
  height: 1.51vw;
  margin-right: 0;
  margin-bottom: 0.52vw;
}

.cart_info2_bt2.checkout {
  width: 6.04vw;
  height: 1.51vw;
  margin-right: 0;
  margin-bottom: 0.52vw;
}

.cart_info2_bt3.checkout {
  width: 6.04vw;
  height: 1.51vw;
  margin-right: 0;
}

.cart_info2.checkout {
  align-items: flex-end;
}

.cart_count.checkout {
  margin-right: 6.87vw;
}

.cart_info1.checkout {
  margin-bottom: 1.25vw;
}

.cart_item_space.checkout {
  margin-top: 2.18vw;
  margin-bottom: 2.18vw;
}

.cart_img.checkout {
  height: 6vw;
}

.cart_list.checkout {
  border: none;
}

hr {
  border: 1px solid rgba(37, 37, 37, 0.15);
}

.discount_hr {
  margin-top: 2.68vw;
  margin-bottom: 1.56vw;
}

.total-summary h4 {
  color: #252525;
  font-size: 0.83vw;
  font-weight: 500;
}

.discount_code input {
  width: 19.89vw;
  height: 2.6vw;
  border-radius: 10px;
  border: 0.5px solid #719ECE;
  /* opacity: 0.20000000298023224; */
  box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 5%);
  margin-top: 0.67vw;
  margin-right: 1.19vw;
  padding-left: 8px;
}

.discount_code input:focus {
  outline: none;

}

.discount_code button {
  border-radius: 10px;

  /* background: rgba(1, 174, 240, 0.20); */
  border: none;
  width: 7.13vw;
  height: 2.6vw;
  color: #fff;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
}

.discount_code {
  margin-bottom: 1.35vw;
}

.cart_total.checkout {
  margin-left: 0;
  width: 27.84vw;
  margin-top: 0;
}

.cart_count1 {
  margin-bottom: 0.72vw;
}

.cart_total.checkout button {
  margin-top: 0.98vw;
  width: 28.22vw;
}

.total-summary.checkout {
  margin-bottom: 3.95vw;
}

.container.account {
  width: 75.52vw;
}

.account_subtitle {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.account_page_title {
  margin-top: 5.20vw;
  margin-bottom: 0.83vw;
}

.account_subtitle {
  margin-bottom: 1.56vw;
}

.account_menu,
.account_menus {
  display: flex;
  column-gap: 5.28vw;
}

.account_menu button {
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  background-color: #fff;
  border: none;
  padding: 0;
}

.account_menu button:hover {
  color: #252525;
}

.account_menu li {
  padding-top: 1vw;
  padding-bottom: 1.71vw;
  border-bottom: 2px solid transparent;
}

.account_menus li {
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.hr_set {
  margin-top: -2px;
}

.account_menu li:hover {
  border-bottom: 2px solid #01AEF0;

}

.account_detail_title {
  margin-top: 2.6vw;
  margin-bottom: 2.08vw;
}

.account_detail {
  display: flex;
  column-gap: 2.65vw;
}

.checkout_form_tel.account input {
  width: 37.39vw;
}

.one_liner {
  display: flex;
  column-gap: 3.02vw;
}

.one_liner input {
  width: 17.18vw !important;
}

.account_detail_right button {
  border-radius: 40px;
  background: #01AEF0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  width: 11.92vw;
  height: 2.60vw;
  border: none;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  margin-top: 2.6vw;
  margin-bottom: 5.2vw;
}
.account_detail_left {
  position: relative;
}

.profile_img {
  width: 8.02vw;
}
.file_input {
  position: absolute;
  top: 18%;
  left: -13%;
  z-index: 10;
  opacity: 0;
}

.account_detail_left span {
  position: absolute;
  left: 67%;
  padding-inline: 10px;
  padding-top: 9px;
  background-color: #01AEF0;
  color: #fff;
  font-weight: 400;
  border-radius: 50%;
  top: 16%;
  padding-bottom: 4px;
  cursor: pointer;
}

.checkout_form_tel.account.first h3 {
  margin-top: 0;
}
.profile {
  border-radius: 50%;
}

.account_subtitle span {
  color: #555;
}

.checkout_form_tel.account.area textarea {
  width: 37.39vw;
  border-radius: 10px;
  border: 1px solid rgb(0 0 0 / 20%);
  margin-top: 0.67vw;
}

.checkout_form_tel.account.gender select {
  width: 17.18vw;
  height: 2.6vw;
  border-radius: 10px;
  border: 1px solid #000;
  opacity: 0.20000000298023224;
  box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 5%);
  margin-top: 0.67vw;
}

.checkout_form_tel.account select {
  width: 29.01vw;
  height: 2.6vw;
  border-radius: 10px;
  border: 1px solid #000;
  opacity: 0.20000000298023224;
  box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 5%);
  margin-top: 0.67vw;
}


.product_detail_input input,
.product_detail_input select {
  width: 15.8vw;
  height: 2.6vw;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 0.67vw;
  padding: 0 0.5vw;
}

.product_detail_input.additional_costs input {
  width: 6.8vw
}

.arrivals_wrapper .product-tag {
  opacity: 0;
}

.discover-box2 {
  background-color: rgba(3, 128, 176, 0.1);
}

.discover-box3 {
  background-color: rgba(50, 56, 80, 0.1);
}

.checkout_shipping_form {
  padding-bottom: 1.3vw;
  margin-top: 2.03vw;
  width: 41.56vw;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.checkout_payment_form {
  margin-top: 2.03vw;
  width: 41.56vw;
  padding-bottom: 1.3vw;
  border-radius: 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.spe_obj {
  position: absolute;
  right: 6.45vw;
  top: 4.21vw;
  width: 2.18vw;
}

.spe_obj2 {
  position: absolute;
  left: 24.01vw;
  top: 1.40vw;
  width: 1.35vw;
}

.spe_obj3 {
  position: absolute;
  left: 29.47vw;
  bottom: 3.28vw;
  width: 0.62vw;
}

.spe_offer {
  position: relative;
}

.slide-title h2 {
  display: flex;
  justify-content: space-between;
}

.slide-title .discover_heading,
.arrivals_title .discover_heading,
.expert_main_head .discover_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account_page_title img,
.account_detail_title img,
.category_title.checkout img,
.category_title.cart img,
.signup_page_title img,
.signin_page_title img,
.add_address_page_title img,
.order_detail_page_title img,
.also_pur_title img,
.collection_title img {
  display: none;
}

.also_pur_title {
  margin-top: 5.2vw;
}

.signup_page_title,
.signin_page_title {
  margin-top: 5.20vw;
}

.sign_up_wrapper,
.sign_in_wrapper {
  padding-bottom: 5.2vw;
}

.form_submit_form {
  margin-top: 2.6vw;
}

.frm_button {
  border-radius: 40px;
  background: #01AEF0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  width: 9.92vw;
  height: 2.6vw;
  border: none;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
}

.sign_container {
  width: 29.01vw;
}

.forgot_password {
  text-decoration: none;
  color: #555;
  font-family: Poppins;
  font-size: 0.83vw;
}

.order_box_delete button {
  width: 9.01vw;
  height: 2.6vw;
  border-radius: 40px;
  background: #f02c01;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  border: none;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
}

.order_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_box_img {
  width: 9vw;
  height: 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: rgba(3, 128, 176, 0.05);
}

.order_box_img img {
  width: 7vw;
  mix-blend-mode: multiply;
  display: block;
  margin: 0 auto;
}

.order_box {
  margin-bottom: 1.5vw;
}

.order_box_title h3 {
  font-family: Poppins;
  font-size: 1.04vw;
  font-weight: 600;
  color: #252525;
  margin-top: 1.8vw;
}

.order_box_img_content {
  display: flex;
  column-gap: 2vw;
}

.order_list_table {
  text-align: center;
}

.order_list_table th {
  background-color: rgba(3, 128, 176, 0.05);
  font-size: 1.04vw;
  font-weight: 600;
  font-family: poppins;
  color: #252525;
}

.order_list_table td,
.order_list_table td button {
  font-size: 0.83vw;
  font-weight: 500;
  font-family: poppins;
  color: #555;
}

.w-75 {
  width: 75vw !important;
}

.order_list_main .slide-title h2 {
  justify-content: center;
}

.order_list_table th,
.order_list_table td {
  padding: 1.3vw 0;
}

.order_list_table td {
  padding: 1vw 0;
}

.order_list_table td button {
  width: 6vw;
  border-radius: 40px;
  height: 2vw;
  background-color: #f02c01;
  border: none;
  color: #FFF;
}

.address_wrapper {
  display: flex;
  justify-content: space-evenly;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.billing_address_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.75vw;
  padding-bottom: 0.75vw;
}

.address {
  font-size: 0.83vw;
  width: 30vw;
  font-family: poppins;
  font-weight: 500;
}

.billing_address_list,
.shipping_address_list {
  width: 100%;
  text-align: center;
}

.address_num {
  font-size: 1.04vw;
  font-family: poppins;
  font-weight: 500;
}

.billing_address_list>.order_box_delete button,
.shipping_address_list>.order_box_delete button {
  margin-top: 1.5vw;
  margin-bottom: 3vw;
}

.order_detail_page_title {
  margin-top: -2.8vw;
}

.group_input {
  display: flex;
  justify-content: space-between;
}

.group_input .checkout_form_tel:first-child input {
  width: 13.5vw;
}

.group_input .checkout_form_tel:nth-child(2) input {
  width: 13.5vw;
}

.portal_box {
  width: 12.18vw;
}

.portal_list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2.501vw;
  column-gap: 6vw;
  text-align: center;
}

.portal_content {
  background-color: #01AEF0;
  color: #fff;
  padding: 0.5vw 0.8vw;
  margin-top: 0.5vw;
}

.portal_content h3 {
  font-size: 0.93vw;
  font-weight: 500;
}

.order_detail_wrap {
  /* flex-direction: column; */
  justify-content: center;
      column-gap: 5vw;

}

.cart_list.order {
  border: none;
}

.order_detail_wrapper.order {
  width: 75.52vw;
}

.cart_total.order {
  margin: auto;
}

.cart_detail.order {
  justify-content: space-between;
}

.giveaway_wrapper {
  width: 100%;
  margin: 0 auto;
  background-color: #BEEDF9;
}

.footer_logo_img img {
  mix-blend-mode: multiply;
  width: 35vw;
  display: block;
  margin: 0 auto;
}

.inout_field {
  display: flex;
  text-align: center;
  flex-direction: column;
}

.giveaway_wrapper form {
  background-color: #122B53;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  width: 28vw;
  margin: 0 auto;
  margin-top: -23vw;
  border-radius: 30px;
  padding: 2vw;
  z-index: 99;
  font-weight: 600;
  position: relative;
}

.giveaway_header img {
  display: block;
  margin: 0 auto;
  width: 55vw;
}

.giv_form_bg img {
  display: block;
  margin: 0 auto;
  width: 55vw;
  z-index: 1;
  position: relative;
  margin-top: -3vw;
}

.giveaway_wrapper form .inout_field label {
  font-size: 1.04vw;
}

.giveaway_wrapper form .inout_field input {
  border-radius: 20px;
  height: 1.8vw;
  text-align: center;
  font-weight: 600;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0.75vw;
  padding-left: 0.5vw;
}

.giveaway_wrapper .form_para p {
  font-size: 1.04vw;
  font-weight: 500;
  text-align: center;
  margin-top: 3vw;
}

.giveaway_wrapper .form_para p:nth-child(2) {
  font-size: 0.83vw;
  font-weight: 100;
  margin-top: 0;
  text-align: center;
}

.footer_social_img img {
  width: 32.5vw;
  display: block;
  margin: 0 auto;
}

.give_footer_content p {
  text-align: center;
  margin: 0 auto;
  font-family: 'Bebas Neue', sans-serif;
  font-size: 1.04vw;
  color: rgb(69, 14, 14);

}

.footer_logo_img {
  margin-top: 1vw;
  margin-bottom: 1vw;
}

.footer_social_img {
  margin-top: 3.2vw;
}

.giveaway_wrapper .inout_button {
  display: flex;
  flex-direction: column;
  column-gap: 0.7vw;
  row-gap: 0.7vw;
  margin-bottom: 2vw;
}

.giveaway_wrapper .inout_button button {
  border-radius: 20px;
  height: 1.8vw;
}

.giveaway_wrapper .para_button button {
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  border-radius: 15px;
  margin-bottom: 2vw;
  padding: 0.3vw 0 0.3vw 0;
  font-size: 0.73vw;
}

.giveaway_wrapper input[type="submit"] {
  font-size: 0.73vw;
  cursor: pointer;
}
.all_products ul{
  display: none;
}
.all_products:hover .all_products_1 {
	display: block;
	position: absolute;
	width: 240px;
	margin-left: -12px;
	margin-top: 12px;
  background-color: #fff;
  padding: 1rem;
}
.all_products_1 > li {
	font-size: .875rem;		
	margin-bottom: 2px;
}
.all_products_1:hover > li a,.all_products_1.active > li a{
	background: rgba(249,250,251);
}
.all_products_1 li a {
  color: #01AEF0;
	font-size: 0.9em;
  padding: 8px;
}
@media screen and (max-width: 468px) {
  .all_products_2.hover_open_menu_1 {
    left: 47vw !important;
    top: 44.5vw !important;
    width: 38vw !important;
    column-count: unset !important;
}
  .header_signup_button{
        margin-left: 0.75rem !important;
    margin-top: 10px !important; 
  }
  .giveaway_header img {
    width: 100vw;
  }

  .giv_form_bg img {
    width: 100vw;
  }

  .giveaway_wrapper form {
    width: 50vw;
    padding-top: 3vw;
    padding-bottom: 3vw;
    margin-top: -42vw;
  }

  .giveaway_wrapper form .inout_field input {
    height: 4.8vw;
  }

  .giveaway_wrapper form .inout_field label {
    font-size: 2.5vw;
  }

  .giveaway_wrapper .inout_button button {
    height: 4.8vw;
    font-size: 2.5vw;
  }

  .giveaway_wrapper .form_para p {
    font-size: 2.74vw;
    margin-top: 7vw;
  }

  .giveaway_wrapper .form_para p:nth-child(2) {
    font-size: 2.3vw;
  }

  .giveaway_wrapper .para_button button {
    font-size: 2vw !important;
  }

  .giveaway_wrapper input[type="submit"] {
    font-size: 1.73vw;
  }

  .footer_social_img img {
    width: 58.5vw;
  }

  .footer_logo_img img {
    width: 63vw;
  }

  .give_footer_content p {
    font-size: 3.04vw;
  }
}

.inout_button button.active {
  background-color: #BEEDF9;
  color: #122B53;
}

.giv_error {
  color: red;
  font-size: 0.73vw;
  margin-top: 0.2vw;
  margin-bottom: 0vw
}

.para_button button.active {
  background-color: #BEEDF9;
  color: #122B53;
}

.giv_error.mulsel {
  text-align: center;
  margin-top: 0.1vw;
}

#panel1a-header,
#panel2a-header,
#panel3a-header,
#panel4a-header {
  border-radius: 14px;
  background: rgba(3, 128, 176, 0.05);
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  opacity: 0 !important;
}

.MuiPaper-root ul {
  list-style: disc;
  padding-left: 2vw;
}

.portal_img img {
  width: 10vw;
}

.give_footer_content {
  padding-bottom: 2vw;
}

/* .product_option_img{
  overflow-y: scroll;
} */
#panel1a-header p {
  color: #252525;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
}

/* .product_option_img {
  position: relative;
  max-width: 100%;
  overflow: hidden;
}

.product_slider_container {
  display: flex;
  transition: transform 0.3s ease-in-out;
  width: 35vw;
}

.product_option2_img {
  flex: 0 0 100%;
}

.product_option2_img img {
  margin: 0 auto;
  display: block;
  height: auto;
}

.product_slider_container.active {
  transform: translateX(-${(activeSlide * 100) / subproduct.length}%);
}



.slider_buttons button:focus {
  outline: none;
}
.product_option2_img {
  flex: 0 0 11.5vw;
  order: 4;
  
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer; /* Add cursor pointer to indicate it's clickable 
}

.product_option2_img.next{
  order: 3;
}
.product_option2_img.prev{
  order: 1;
}
.product_main_img img {
  width: 100%;
  height: auto;
}
.order_class{
  order: 2;
} */
.product_option_img {
  position: relative;
  overflow: hidden;
}

.product_option2_img.active {
  border: 2px solid rgba(3, 128, 176, .1);
  /* order: 2; */
}

.slider_buttons {
  position: absolute;
  bottom: 5vw;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 2vw;
  padding-right: 2vw;
}

.product_slider_container {
  display: flex;
  width: 35vw;
  transition: transform 0.5s ease;
  transform: translateX(11.5vw);
}

.product_option2_img {
  flex: 0 0 11.5vw;
  transition: opacity 0.5s ease;
}

.product_option2_img.active {
  opacity: 1;
}

.product_option2_img.prev,
.product_option2_img.next {
  position: absolute;
  top: 0;
  opacity: 0;
}

.product_option2_img.prev {
  left: -100%;
}

.product_option2_img.next {
  left: 100%;
}

.product_option_img button {
  background: #01AEF0 !important;
  border: none;
  width: 2vw;
  height: 2vw;
  position: absolute;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.slider_buttons {
  display: flex;
  justify-content: space-between;
}

.slider_buttons button {
  font-size: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.product_img {
  max-width: 600px;
  /* Adjust this to your desired width */
  margin: 0 auto;
}

#panel1a-content p {
  font-family: poppins;
  font-size: 0.83vw;
  color: #555;
  font-weight: 400;
}

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent background to create an overlay effect */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Styles for the popup content */
.popup {
  width: 30vw;
  height: 12vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  /* White background color */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* Shadow effect */
}

/* Optional styles for the paragraph inside the popup */
.popup p {
  margin: 0;
  font-size: 1.5vw;
  font-weight: 500;
  font-family: poppins;
  text-align: center;
}

.popup_img {
  width: 1.5vw;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
  margin-bottom: 1vw;
}

.product_option2_img {
  transition: transform 0.3s ease-in-out;
  transform: scale(0.8);
  /* You can adjust the scale factor as per your preference */
  cursor: pointer;
}

.product_option2_img.active {
  transform: scale(1);
  /* You can adjust the scale factor as per your preference */
}

.product_option2_img.prev,
.product_option2_img.next {
  opacity: 0.7;
  /* You can adjust the opacity for previous and next slides */
}

/* Example animation for the image in the main slide */
.product_main_img img {
  transition: opacity 0.3s ease-in-out;
}

.product_main_img img {
  opacity: 0;
}

.product_main_img img {
  opacity: 1;
}

.product_slider_wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

/* Example animation for the image in the main slide */
.product_main_img img {
  transition: opacity 0.3s ease-in-out;
}

.product_main_img img {
  opacity: 0;
}

.product_main_img img {
  opacity: 1;
}

.next_btn {
  top: 50%;
  transform: translate(0, -50%);
  right: 10%;
}

.prev_btn {
  top: 50%;
  transform: translate(0, -50%);
  left: 10%;
}

.dropdown-container {
  position: relative;
}


.dropdown-content {
  position: absolute;
  top: 3vw;
  left: 0;
  border-radius: 14px;
  background: #FFF;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.10);
  padding: 1vw;
  z-index: 1;
  width: 18vw;
  max-height: 300px;
  /* Adjust the maximum height as needed */
  overflow-y: auto;
  /* Enable vertical scrolling when content exceeds max height */
}

.dropdown-content label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;

}

.checkbox-margin {
  margin-right: 8px;
}

.apply_btn {
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 40px;
  background: #01AEF0;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  border: none;
  padding: 0.67vw 1.74vw;
  text-align: center;
}

.dropdown-buttons {
  display: flex;
  justify-content: space-between;
  margin: 2vw 0 0;
}

.dropdown-hr {
  margin: 10px 0;
  border: none;
  border-top: 1px solid #252525;
  opacity: 0.15;
}

.dropdown-container input[type="checkbox"] {
  border-radius: 9px;
  border: 1px solid #ccc;
  /* opacity: 0.2; */
  width: 20px;
  /* Increase the width */
  height: 20px;
  /* Increase the height */
  margin-right: 10px;
}

.current-page {
  color: #fff;
  background: #01AEF0;
  font-family: Poppins;
  font-size: 1.04vw;
  font-weight: 500;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6vw;
  height: 2.6vw;
  border-radius: 50%;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}

.space_between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signup_cont {
  color: #01AEF0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.back_category_icon {
  position: absolute;
  top: -4vw;
  left: 9vw;
}

.dropdown-content .dropdown_select {
  width: 16vw;
}

.dropdown-buttons .w-100 {
  width: 100%;
}

.error {
  color: red;
  font-size: 0.9vw;
}

.text-danger {
  color: red;
}

.new_client_menu ul {
  display: flex;
  align-items: center;
  column-gap: 3vw;
}

.new_client_menu ul li {
  color: #555;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 4px solid transparent;
  padding-bottom: 0.93vw;
}

.new_client_menu ul li:hover {

  border-bottom: 2px solid #01AEF0;
}

.new_client_menu ul li.active {

  border-bottom: 2px solid #01AEF0;
}

.new_client_menu {
  margin-bottom: 1vw;
}

.new_client_menus {
  margin: 0;
}

.new_client_menus ul li.active {
  border-bottom: 2px solid #01AEF0;
}

.new_client_menus ul li {
  color: #555;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 4px solid transparent;
  padding-bottom: 0.93vw;
}

.styled-table {
  border-collapse: collapse;
  margin: 0 0 25px;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.styled-table thead tr {
  background-color: #01AEF0;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #01AEF0;
}

.table.w-100 {
  width: 100%;
  margin-bottom: 1vw;
}

.text-justify {
  text-align: justify;
}

.mb-2 {
  margin-bottom: 2vw;
}

.mb-4 {
  margin-bottom: 4vw;
}

.ml-1 {
  margin-left: 1vw;
}

.mt-2 {
  margin-top: 2vw;
}

.shipping_address_detail,
.billing_address_detail {
  margin: 2vw 0;
}

.products_list button.product_save {
  border: 0;
}

.modal {
  position: fixed;
  top: 0;
  width: 90vw;
  height: 90vh;
  border: 1px solid #00b0ed;
  border-radius: 5px;
  /* overflow-x: scroll; */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99 !important;
}

.modal .order_box_delete button svg {
  font-size: 1.83vw;
}

.modal .order_box_delete button {
  width: 2.6vw;
}

.modal .order_box_delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vw 0 1vw;
}

.order_box_delete.artwork_btn {
  display: flex !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.order_box_delete.artwork_btn button {
  font-size: 0.83vw;
  width: 6.5vw !important;
}

.address_wrapper .order_box_delete button {
  margin: 1.5vw 0 1.5vw;
}


.w-100 {
  width: 100%;

}



.artwork_wrapper div img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.slick-slide {
  width: auto !important;
  margin-right: 24px !important;
}

.slick-track {
  width: 100% !important;
  display: flex !important;
}

.slick-list {
  width: 96vw;
}

.arrivals_list .slick-list {
  width: 85vw;
}

.description_editor textarea {
  background-color: rgba(1, 174, 240, 0.07);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.10);
  width: 41vw;
  height: 15vh;
  padding: 1.8%;
  margin-bottom: 0.8vw;
}

.description_editor .cart_info2_bt3 {
  width: 10vw;
  height: 1.5vw;
  font-size: 0.8vw;
  border-radius: 10px
}

.maintenance-detail {
  color: #3842b3;
  margin-bottom: -90px;
  z-index: 1;
  line-height: 27px;

}

.maintenance-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.maitenance-button {
  background-color: #3842b3;
  color: #fff;
  line-height: 14px;
  font-size: 14px;
  border-radius: 20px;
  padding: 8px 10px;
}

/* common style */

.text-center {
  text-align: center;
}

.w-50 {
  width: 50%;
}

.mb-3 {
  margin-bottom: 1rem;
}

ul.explore-nav-menu {
  overflow: hidden;
}

.product_quote_quantity {
  flex: 1 0 50%;
  box-sizing: border-box;
  margin-bottom: 2vw;
}

.product_artwork_option {
  cursor: pointer;
  padding: 10px;
  /* Define the default styles for the options */
  color: #333;
  /* Default text color */
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.product_artwork_option.selected {
  color: #FFF;
  /* Text color for the selected option */
  background: #01AEF0;
  /* Background color for the selected option */
}

.product_shipping_type {
  cursor: pointer;
  padding: 10px;
  /* Define the default styles for the options */
  color: #333;
  /* Default text color */
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.product_shipping_type.selected {
  color: #FFF;
  /* Text color for the selected option */
  background: #01AEF0;
  /* Background color for the selected option */
}

.pricefont {
  font-size: 1.5vw;
  margin-left: 0.8vw;
}

.m-auto {
  margin: auto;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.gap-2 {
  gap: 0.75rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px;
}

.fw-500 {
  font-weight: 500;
}

.align-item {
  align-items: baseline;
}

.text-blue {
  color: #01aef0;
}

.color-size-qty-btn {
  border: 1px solid #01aef0 !important;
  border-radius: 20px;
  padding: 4px 10px;
  cursor: pointer;
}

.active-color-size-qty-btn {
  color: #ffffff;
  background-color: #01aef0 !important;
}

.signup_portal_input {
  width: 29.01vw !important;
}

.pre_line {
  white-space: pre-line;
}

.pdf_img {
  margin: 0px 10px 0px 0px;
  padding: 0px;
  height: 20px;
}

.bg_gray {
  background-color: gray !important;
}

.cate-header-ul>li {
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
  /* width: 230px; */
}

/* 
.child_menu {
	display: none;
	background: #fff;
	margin-top: 10px;
	position: absolute;
	z-index: 9999;
	overflow: auto;
	margin-left: -10px;
	padding-bottom: 10px;
	padding-top: 10px;
	height: auto;
  max-height: 250px;
	grid-template-rows: repeat(7,minmax(0,1fr));
	grid-auto-flow: column;
	grid-gap: 3px 10px;
	width: 25%;
  min-width: 300px;
  max-width: 400px;
} */
.child_menu {
  display: none;
  /* display: grid; */
  background: #fff;
  margin-top: 8px;
  position: absolute;
  z-index: 9999;
  overflow: auto;
  margin-left: -10px;
  padding-bottom: 10px;
  padding-top: 10px;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  /* Adjust column width */
  grid-gap: 3px 10px;
  width: auto;
  min-width: 300px;
  /* Adjust this value if necessary */
  max-height: none;
  /* Remove the maximum height constraint */
  /* margin-left: calc(-7%); */
}



.child_menu a {
  color: #01aef0 !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
}

.cate-header-ul li:hover,
.cate-header-ul li:hover a {
  background: #fff;
  color: #01aef0;
}

li:hover .child_menu {
  display: grid;
}

li:last-child:hover .child_menu {
  margin-left: -10%;
}

.child_menu li {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 5px 20px;
}

.h-w-100 {
  height: 75px;
  width: 75px;
  object-fit: contain;
}

.artwork_image {
  position: relative;
  height: 75px;
  width: 75px;
}

.delete_selected_mage {
  position: absolute;
  cursor: pointer;
  color: red;
  top: 0;
  right: 0;
}

.allImage {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.apply_attribute_btn {
  position: sticky;
  bottom: -14px;
  background-color: #fff;
}

.z-1 {
  z-index: 1;
}

.aprove_btn {
  margin-right: 0;
  background-color: green;
  color: #fff;
  width: 120px !important;
  font-size: 18px !important;
  padding: 15px !important;
}

.reject_btn {
  margin-right: 0;
  background-color: red !important;
  color: #fff;
  width: 120px !important;
  font-size: 18px !important;
  padding: 15px !important;
}

.atwork_model_btn {

  display: flex;
  gap: 0.5rem;
}

.atwork_div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
 
}


.pre_ifrem_nex {
  width: 100%;
  height: 65vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.me-2 {
  margin-right: 8px;
}

.artwork_prev_next_btn {
  padding: 8px 12px;
  background-color: #01aef0;
  border: none;
  color: #fff;
  border-radius: 50%;
  font-weight: 900;
}

.nav_bar {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #fff;
}



.home_page_slider .prev,
.home_page_slider .next {
  cursor: pointer;
  position: absolute;
  top: 37%;
  /* transform: translateY(-50%); */
  font-size: 20px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  padding: 4px 13px;
  border-radius: 50%;
}

.home_page_slider .prev:hover,
.home_page_slider .next:hover {
  background: #37415180;
}

.home_page_slider .prev {
  left: 2%;
}

.home_page_slider .next {
  right: 2%;
}

.home_page_slider .slider.slide {
  display: none;
}

.home_page_slider .slider.active {
  display: block;
}

#error-page {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-bottom: 60px;
}

#error-page .content {
  max-width: 600px;
  text-align: center;
}

#error-page .content h2.header {
  font-size: 18vw;
  line-height: 1em;
  position: relative;
}

#error-page .content h2.header:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-repeating-linear-gradient(-45deg, #01AEF0, #69a6ce, #b98acc, #01AEF0, #b98acc, #69a6ce, #01AEF0);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.25);
  animation: animate 10s ease-in-out infinite;
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }

  25% {
    background-position: 100% 0;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

#error-page .content h4 {
  font-size: 1.5em;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #000;
  font-size: 2em;
  max-width: 600px;
  position: relative;

}

#error-page .content h4:after {
  position: absolute;
  content: attr(data-text);
  top: 0;
  left: 0;
  right: 0;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#error-page .content p {
  font-size: 1.2em;
  color: #0d0d0d;
}

#error-page .content .btns {
  margin: 25px 0;
  display: inline-flex;
}

#error-page .content .btns a {
  display: inline-block;
  margin: 0 10px;
  text-decoration: none;
  border: 2px solid #69a6ce;
  color: #69a6ce;
  font-weight: 500;
  padding: 10px 25px;
  border-radius: 25px;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

#error-page .content .btns a:hover {
  background: #69a6ce;
  color: #fff;
}

.cart_wrap .cart_detail {
  justify-content: space-between;
}

.cart_info input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #01AEF0;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  width: 9.54vw;
  height: 2.6vw;
  border-radius: 40px;
  background: #01AEF0;
  color: #FFF;
  font-family: Poppins;
  font-size: 0.83vw;
  font-weight: 500;
  border: none;
  box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 5%);
}

.cart_info input[type=file] {
  font-family: poppins;
}

.faq_title,
.contact_title {
  margin-top: 2.2vw;
  margin-bottom: 0.83vw;
}

.faqpage p {
  color: #252525;
  font-family: Poppins;
  font-size: 1.04vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.faqpage1 li,
.faqpage1 p {
  font-family: poppins;
  font-size: 1vw;
  color: rgb(85, 85, 85);
  font-weight: 400;
}

.faqpage {
  min-height: unset !important;
  height: 4vw !important;
  margin-top: 2vw !important;
  background-color: transparent !important;
  border: 2px solid #01AEF0 !important;
  border-radius: 1.5vw !important;
}

.faq_wrapper .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.faq_wrapper .MuiAccordionSummary-expandIconWrapper {
  font-size: 2vw !important;
  height: 3vw !important;
}

.terms_wrapper .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-disabled {
  background-color: transparent !important;
}

.terms_wrapper .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-disabled {
  opacity: 1 !important;
}

.terms_title {
  margin-top: 1vw !important;
  font-size: 1.3vw !important;
  color: #252525;
  font-family: poppins;
}

#scrollableDiv {
  scrollbar-width: none;
  /* Width of the scrollbar */
  scrollbar-color: #fff #fff;
  /* Color of the thumb and track */
}

dialog {
  width: 98.9vw;
  z-index: 99;
  top: 0;
  height: 100vh;
  border: none;
  position: fixed;
}

dialog .sign_container {
  width: 30vw;
  height: 30vw;
  min-width: 360px;
  min-height: 360px;
  border: 1px solid rgba(3, 128, 176, 0.2);
  top: 5vh;
  margin: 0 auto;
  border-radius: 14px;
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  padding: 30px;
}
.mobile_menu_wrapper{
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  height: 100vh;
  display: none;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  padding: 4vw 4vw 7vw;
  width: 100%;
  left: 0 !important;
}
.mobile_menu_wrapper .search-results {
	left: 0;
	width: 100%;
}
.mobile_menu_wrapper .search-feild input::placeholder{
  font-size: 3.5vw;
}
.mobile_menu_wrapper .search-feild input{
  font-size: 3.5vw;
  width: 78.95vw;
  padding-left: 5vw;
  margin-bottom: 3vw;
}

.mobile_menu_wrapper .header-ul li {
    color: #252525;
    font-family: Poppins;
    font-size: 3.5vw;
    text-align: start;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.mobile_menu_wrapper .header-ul .call-li {
    margin-left: 0vw;
    /* margin-right: 2.17vw; */
}

.mobile_menu_wrapper .call-icon{
  width: 5vw;
  margin-right: 3vw;
}
.mobile_menu_wrapper.active{
  left: 10%;
  opacity: 1;
  display: inline-block;
  visibility: visible;
}
.mobilemenu_close_icon{
  text-align: end;
  margin-bottom: 2vw;
}

.mobile_cat_header ul{
  overflow: scroll;
}

.mobile_cat_header ul li{
      flex-grow: 1;
    flex-shrink: 0;
}

.mobile_cat_header ul li a{
  font-size: 2.8vw;
}

.hover_open_menu{
  position: absolute;
  top: -350%;
  transition: 0.2s;
  display: block !important;
  /* opacity: 0; */
  visibility: hidden;
}

.all_products:hover .all_products_1.hover_open_menu {
  top:88%;
  opacity: 1;
  visibility: visible;
    /* background-color: #fff; */
}
.all_products_1.hover_open_menu {
    background-color: #fff;
    z-index: -1 !important;
    width: fit-content !important;
}
header{
  z-index: 999;
  position: relative;
  background-color: #fff;
}
.cate-header-ul li:hover a, .cate-header-ul li a, .cate-header-ul li .hover_open_menu li:hover, .cate-header-ul li .hover_open_menu li{
  background-color: transparent !important;
}
.cate-header-ul li .hover_open_menu li{
  width: 14vw;
  background-color: #fff !important;
}
.cate-header-ul li .hover_open_menu li a{
  display: flex;
    align-items: center;
    justify-content: space-between;
}
.cate-header-ul li .hover_open_menu li .hover_open_menu_1 a{
  color: #555;
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.all_products_2.hover_open_menu_1{
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 16vw;
  top: 0vw;
  width: 12.5vw;
  transition: 0.2s;
  height: 100% !important;
    column-count: 1;
}
.sub_cate_menu_:hover .all_products_2.hover_open_menu_1{
  opacity: 1;
  visibility: visible;
}
.category-header-wrapper .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
input[placeholder="Search"]::placeholder{
  color: #fff;
}
input[placeholder="Search"]{
  border-color: #fff;
  padding: 0.5vw 1vw;
  color: #fff;
}
.search-icon{
  filter: brightness(100);
}
.hover_open_menu.portal{
  left: 10vw !important;
  margin-left: 0 !important;
  column-count: 3;
  top: -632%;
}
.cate-header-ul li .hover_open_menu li:hover{
  background-color: rgb(237, 237, 237) !important;
}
.child_menu.hover_open_menu.portal li{
  padding: 0;
}
.all_products:hover .all_products_1.hover_open_menu.portal {
    top: 96%;
}
.login_button{
  color: #FFF;
    font-family: Poppins;
    font-size: 0.83vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #01AEF0;
    width: 9.01vw;
    height: 2.6vw;
    border: none;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}
.sub_cate_menu_.brand{
  display: flex;
  width: 100% !important;
  align-items: center;
}
.sub_cate_menu_.brand img{
  width: 4vw;
}
.all_products_1.Collections a, .all_products_1.Collections a, .child_menu.all_products_1.Collections > .sub_cate_menu_ a{
  color: #555 !important; 
}
.sub_cate_menu_.brand a, .sub_cate_menu_.apparel a{
  color: #555 !important;
}
.header_signup_button{
  color: #FFF !important;
    font-family: Poppins;
    font-size: 0.83vw;
    font-style: normal;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #01AEF0;
    width: 9.01vw;
    height: 2.6vw;
    border: none;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}
.close_pop{
  margin-left: 15px;
}
.child_menu.cate-header-ul.all_products_1.hover_open_menu.portal.Brand_ {
	/* column-count: 6; */
	/* overflow: hidden; */
	display: flex !important;
	flex-wrap: wrap;
	width: 80% !important;
}
.Brand_ .sub_cate_menu_.apparel img{
  width: 4rem;
}
.Brand_ .sub_cate_menu_.apparel {
	width: calc(100% / 7);
  display: flex;
  padding: 0px 2px !important;
  /* margin-bottom: 15px; */
}
.Brand_ .sub_cate_menu_.apparel.branding {
	width: calc(100% / 6) !important;
 
}
.child_menu.cate-header-ul.all_products_1.hover_open_menu.portal.collections_{
  column-count: 5;
  overflow: hidden;
}
.child_menu.cate-header-ul.all_products_1.hover_open_menu.portal.branding_{
  column-count: 5;
  overflow: hidden;
}
.child_menu.cate-header-ul.all_products_1.hover_open_menu.portal.apparel_{
  column-count: 2;
  overflow: hidden;
  /* padding-right: 50%; */
}
.faq_wrapper.terms_wrapper .Mui-disabled{
 background-color: #fff;
}
.faqpage > p{
  color: #252525 !important;
}
.Mui-disabled {
  opacity: 1 !important;
}
.search-results {
    top: 100%;
    left: 10vw;
    width: 79.5vw;
}
.search-container {
    position: unset;
}
.search-results{
  height: 80vh;
  min-height: 80vh;
  max-height: 80vh;
  border: none !important;
  background-color: #fff;
}
.search-results ul ul li{
  border: none !important;
}
.search-results ul ul {
  display: flex;
  flex-wrap: wrap;
  row-gap: 2vw;
}
.search-results.close {
    height: 10vh;
    min-height: 20vh;
    align-items: center !important;
    display: flex !important;
    max-height: 80vh;
    border: none !important;
    background-color: #fff;
    justify-content: center;
}
.search .category-header-wrapper .search-feild {
  opacity: 0;
  visibility: hidden;

}
.search .search-feild{
  display: flex;
    justify-content: center;
}
.search input[placeholder="Search"] {
    border-color: #01AEF0;
    padding: 0.5vw 1vw;
    color: #555;
    margin-top: 2.5vw;
}
.search input[placeholder="Search"]::placeholder {
    border-color: #01AEF0;
    color: #555;
}
.explore-box {
    border-radius: 14px;
    background: #FFF;
    width: 38.9vw;
    padding-top: 3.8vw;
    padding-left: 2.61vw;
    padding-right: 2.61vw;
    padding-bottom: 3.17vw;
    flex-grow: 0;
    flex-shrink: 0;
}
.explore-head-img img {
    width: 12.34vw;
    height: 12.34vw;
}
.explore-head-img {
    background-color: transparent;
    width: 13.95vw;
    height: 13.95vw;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.explore-box-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
        column-gap: 3vw;

}
.explore-head-title h3 {
    color: #252525;
    font-family: Poppins;
    font-size: 1vw;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.7vw;
    line-height: normal;
}
.explore-head-title p{
      color: #252525;
    font-family: Poppins;
    font-size: 0.83vw;
    margin-bottom: 1.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.explore-box {
    border-radius: 14px;
    background: #FFF;
    width: 38.9vw;
    padding-top: 0.8vw;
    padding-left: 2.61vw;
    padding-right: 2.61vw;
    padding-bottom: 1.17vw;
    flex-grow: 0;
    flex-shrink: 0;
}


.spe_ex_button {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.83vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #01AEF0;
    width: 9.01vw;
    height: 2.6vw;
    border: none;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}
.discover-box {
    width: 22.36vw !important;
}
.discover-img img {
    width: 9.08vw;
    height: 9.08vw;
    mix-blend-mode: multiply;
    display: block;
}
.slick-slide {
    margin-right: 1.7vw !important;
}
.category-header-wrapper .container{
  height: 45px ;
}
nav.MuiPagination-root ul{
  justify-content: center !important;
}
@media (max-width: 520px) {
  .all_products .all_products_1 {
  padding: 1rem;
}
  .search-container {
    position: relative;
}
  .header_signup_button {
    color: #FFF !important;
    font-family: Poppins;
    font-size: 0.83vw;
    font-style: normal;
    display: flex !important;
   justify-content: center !important;
    align-items: center !important;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #01AEF0;
    width: 22.01vw;
    height: 8.6vw;
    border: none;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}
  .all_products_1.hover_open_menu{
    z-index: 9999 !important;
  }
  .cate-header-ul > li:hover, .cate-header-ul > li:hover > a {
    background: unset !important;
    color: #01AEF0 !important;
}
  .all_products_1.hover_open_menu{
    display: none !important;
  }
  .all_products_1.hover_open_menu.open{
    display: block !important;    
  }
  .mobile_menu_wrapper .css-70qvj9{
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    align-items: start;
  }
  .mobile_menu_wrapper{
    overflow: scroll;
  }
  .product_detail_content{
    margin-top: 5vw;
  }
  .all_products_1{
    /* height: 50vw !important; */
    /* overflow: scroll; */
    position: static !important; 
  }
  .mobile_menu_wrapper .cate-header-ul li .hover_open_menu li {
    width: 100%;
}

  .mobile_menu_wrapper .cate-header-ul > li{
    flex-direction: column;
  }
  .login_button{
    font-size: 2.72vw;
    font-weight: 500;
    border-radius: 40px;
    background: #01AEF0;
    width: 27.01vw;
    height: 7.6vw;
    margin-top: 5vw;
  }
  .cate-header-ul li a {
    color: #555;
}
  .header-logo img {
    width: 59.03vw;
  }
  .child_menu{
    display: none !important;
  }
  .child_menu.open{
    display: block !important;
        position: absolute;
    top: 100%;
    left: 13%;
  }
  .cate-header-ul > li:hover{
    background: unset !important;
    color: #fff !important;
}
.cate-header-ul > li{
      display: flex;
    align-items: center;
}
.cate-header-ul > li svg {
  color: #fff;
}
.all_products_1.hover_open_menu li a {
    color: #555 !important;
}
.all_products > a{
}
.cate-header-ul > li{
  align-items: flex-start;
}
nav .cate-header-ul.MuiBox-root.css-70qvj9 > li a {
  color: #01AEF0 !important;
  
}
.slider-head {
    position: absolute;
    top: 21%;
}
.slider {
    /* background: url(./assets/img/slider.png); */
    background-position: center center;
    background-size: cover;
    height: 51.84vw;
}
.slider-head h3 {
    font-size: 3.04vw;
    font-weight: 500;
    margin-bottom: 6px;
}
.hover_open_menu{
  opacity: unset !important;
}
.all_products_1 li a{
  color: #555 !important;
}
.slider-head h2 {
    font-size: 8.37vw;
    font-weight: 600;
    line-height: 8.68vw;
    margin-bottom: 10px;
}
.child_menu.cate-header-ul.all_products_1.hover_open_menu.portal.open{
  opacity: 1 !important;
  visibility: visible !important;
  column-count: 1;
}
.slider-head button {
    color: #01AEF0;
    font-size: 2.5vw;
    font-weight: 500;
    border-radius: 40px;
    width: 22.01vw;
    height: 6.4vw;
}
.discover-area-wrapper h2 {
    font-size: 4.5vw;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
}
.discover-area-wrapper {
    margin-bottom: 6.25vw;
    margin-top: 6.25vw;
}
.slide-title .discover_heading, .arrivals_title .discover_heading, .expert_main_head .discover_heading {
    
    margin-bottom: 3vw;
}

.button_transparent img{
  width: 6vw;
}
.discover-content h3 {
    font-size: 4VW;
}
.discover-content button {
    font-size: 2.72vw;
    height: 6.6vw;
    width: 21.77vw;
}
.discover-box {
    width: 58.36vw !important;
       padding: 4.08vw 5.4vw;
    height: 36.66vw;
}
.discover-img img {
    width: 24.08vw;
    height: 24.08vw;
}
.arrivals_wrapper h2, .explore-wrapper h2, .trend_wrapper h2{
  color: #252525;
    font-family: Poppins;
    font-size: 4.5vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2.3vw;
    margin-top: 6.2vw;
    font-size: 4.5vw;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0;
    margin-top: 0;
}
.arrivals_box {
    width: 45.34vw;
}
.arrivals_img img {
    width: 37.69vw;
}
.arrivals_content_title h3 {
    font-size: 3.2vw;
    margin-top: 2vw;
    line-height: normal;
}
.trend_wrapper {
    padding-top: 6.25vw;
    padding-bottom: 12.34vw;
}
.trend_para {
    color: #555;
    font-family: Poppins;
    font-size: 2.72vw;
    font-style: normal;
    font-weight: 500;
    margin-top: 1vw;
    line-height: normal;
}
.trend_wrapper .arrivals_box {
    width: 43.94vw;
}
.trend_bottom_btn button, .trend_bottom_btn a {
    color: #FFF;
    font-family: Poppins;
    font-size: 2.73vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 40px;
    background: #01AEF0;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
    border: none;
    padding: 1.67vw 3.74vw;
    text-align: center;
}

.spe_offer {
    height: 74.03vw;
    flex-direction: column;
    margin-bottom: 5.2vw;
    column-gap: 3.9vw;
}
.spe_offer_logo img {
    width: 37.35vw;
    height: unset;
}
.spe_offer_title h3 {
    font-size: 4.5vw;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.88vw;
    line-height: 5vw;
}
.spe_offer_para p {
    font-size: 2.72vw;
    text-align: center;
    margin-bottom: 1.03vw;
}
.spe_offer_button{
  text-align: center;
  align-items: center;
}
.spe_offer_button button {
    font-size: 2.72vw;
    font-weight: 500;
    border-radius: 40px;
    background: #01AEF0;
    width: 27.01vw;
    height: 7.6vw;
}
.spe_offer_img {
  text-align: center;
  align-items: center;
}
.spe_offer_logo{
  text-align: center;
}
.footer{
  flex-direction: column;
}
.footer-logo img {
    width: 55.03vw;
}
.footer-detail p {
    font-size: 3.2vw;
    font-weight: 400;
}
.footer-our-company h2 {
    font-size: 4.5vw;
    margin-top: 3vw;
}

.footer-our-company ul li {
    font-size: 3.2vw;
    line-height: 4.66vw;
}
.footer-contact h2 {
    font-size: 4.5vw;
    font-weight: 600;
    margin-top: 3vw;
  }
  .footer-follow h2 {
  margin-top: 3vw;
    font-size: 4.5vw;
}
.footer-contact ul li {
    font-size: 3.2vw;
    line-height: 4.66vw;
}
.footer-follow .footer-icon img {
    width: 5.51vw;
}
.footer-icons {
    column-gap: 3.17vw;
}
.bottom_footer p {
    font-size: 3.2vw;
}
.slide-title h2 {
    font-size: 4.5vw;
}
.collection_wrapper .arrivals_box {
    width: 43.94vw;
}
.collection_wrapper .spe_offer_wrapper{
  margin-top: 6.25vw;
    margin-bottom: 9.25vw;
}
.new_client_menu ul li {
    font-size: 3.4vw;
    font-weight: 500;
    padding-bottom: 0.93vw;
}
.product_detail {
    flex-direction: column;
}
.product_detail_content {
    width: 100%;
    overflow: scroll;
}
.product_name h2 {
    font-size: 4.5vw;
}
.product_size h5 {
    font-size: 3.2vw;
}
.expert-color h4 {
    font-size: 3.2vw;
  }
  .expert-rating h4 {
    font-size: 3.2vw;
  }
  .expert-rating img {
    height: 3.5vw;
    width: 3.5vw;
}
.product_detail_content h5 span{
      color: #252525;
    font-size: 4.5vw;
    font-weight: 600;
    margin-bottom: 0.31vw;
  }
  .product_size h5 span{
    font-size: 3.2vw;
    
}
.checkout_form_tel h3, .checkout_form_mail h3 {
    font-size: 3.2vw;
}
.frm_button {
    width: 19.92vw;
    height: 7.6vw;
    font-size: 3.2vw;
}
.sign_container {
    width: 75.01vw;
}
.checkout_form_tel input, select, .checkout_form_mail input, .checkout_form_tel textarea {
    width: 75.01vw;
    height: 7.6vw;
    margin-top: 0.67vw;
    padding: 0 2.5vw;
}
.forgot_password {
    font-family: Poppins;
    font-size: 2.72vw;
}
.sign_container {
    width: 75.01vw;
    margin-top: 9vw;
    margin-bottom: 9vw;
}
.checkout_form_tel h3, .checkout_form_mail h3 {
    margin-top: 3.87vw;
}
.signup_portal_input {
    width: 100% !important;
}
.form_submit_form {
    margin-top: 5.6vw;
}
.account_menu button {
    font-size: 2.83vw;
    font-weight: 500;
}
.account_subtitle {
    font-size: 3.2vw;
}
.account_detail_right button {
    width: 35.92vw;
    height: 7.6vw;
    font-size: 3.2vw;
}
.product_head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 5.25vw 1.35vw 0.88vw 5.04vw;
}
.cart_wrap{
  flex-direction: column;
}
.cart_total {
    width: 100%;
    margin-left: 0;
    text-align: center;
}
.cart_title h3 {
    font-size: 4.5vw;
}
.cart_total {
    margin-top: 8.17vw;
}
.cart_count h5 {
  font-size: 3.9vw;
  margin-block: 2px;
}

.cart_total_list h3 {
    font-size: 2.72vw;
}
.cart_total_list h4 {
    font-size: 2.72vw;
}
.cart_total button {
    width: 23.54vw;
    height: 7.6vw;
    font-size: 2.72vw;
}
.cart_total p {
    font-size: 2.72vw;
}
.cart_list {
    border-right: none;
}
.checkout_form_tel.account input {
    width: 50.39vw;
}

.account_detail_left img {
    width: 21.02vw;
}
.checkout_form_tel.account.area textarea {
    width: 50.39vw;
    height: 23vw;
}
.checkout_form_tel.account.gender select {
    width: 22.18vw;
    height: 7.6vw;
}
.one_liner input {
    width: 24.68vw !important;
}
.order_box_delete button {
    width: 19.01vw;
    height: 8.6vw;
    font-size: 3.72vw;
}
.container {
    margin: 0 auto;
    width: 90vw;
}
.cart_list {
    width: 100%;
}
.sign_up_wrapper .group_input{
  width: 75vw;
}
.sign_up_wrapper .group_input .checkout_form_tel {
  width: 48%;
}
.sign_up_wrapper .group_input .checkout_form_tel input {
    width: 100%;
}
.container.account {
    width: 88% !important;
}
.billing_address_box button{
  font-size: 2.72vw;
  width: 17.01vw;
  height: 6.6vw;
  width: 17.01vw;
  height: 6.6vw;
}
.address {
    font-size: 2.72vw;
    width: 48vw;
}
.address_num {
    font-size: 2.72vw;
}
a .order_box_delete > button {
  margin-bottom: 6.25vw !important;
  margin-top: 6.25vw !important;
}
.order_list_table th {
    font-size: 2.7vw;
}
.footer {
    padding-top: 10vw;
    padding-bottom: 2.91vw;
}
.terms_title {
    font-size: 3.2vw !important;
}
.faqpage p {
    font-size: 3.2vw;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
    padding: 19px 17px;
}
.faqpage {
    border-radius: 6.5vw !important;
}
.faqpage1 p {
    font-size: 2.72vw;
}
.terms_title {
    margin-top: 6.25vw !important;
    margin-bottom: 4.255vw;
    
}
.faq_title{
    margin-top: 5.2vw;
    margin-bottom: 0.83vw;
}
.error {
    font-size: 2.72vw;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 34px 16px !important;
}
.container.faq_wrapper{
  margin-bottom: 10vw;
  margin-top: 10vw;
}
.product_main_img img {
    width: 76.31vw;
}
.product_main_img {
    width: 100%;
    height: 75.78vw;
}


.product_img {
    width: 100%;
}
.product_option1_img img, .product_option2_img img {
    mix-blend-mode: multiply;
    width: 23.45vw;
}

.product_option_img button {
    width: 8vw;
    height: 8vw;
}
ul[style="font-family: poppins; font-size: 0.83vw; color: rgb(85, 85, 85); font-weight: 400;"]{
  font-size: 2.72vw !important; 
}
.free_shipping img, .national_shipping img {
    width: 8.56vw;
    height: 8.56vw;
}
.free_shipping h4, .national_shipping h4 {
    font-size: 2.72vw;
}
.free_shipping h5, .national_shipping h5 {
    color: #555;
    font-size: 2.72vw;
    font-weight: 400;
}
.free_shipping, .national_shipping {
    width: 54.38vw;
    height: 31.39vw;
    padding: 6.3vw;
}

.styled-table {
    min-width: 90vw;
    max-width: 90vw;
}
.product_quote1 h3 {
    font-size: 03.72vw;
}
.product_detail_input input, .product_detail_input select {
    width: 15.8vw !important;
    height: 8.6vw;
}
.trend_bottom_btn.pro_detail {
    margin-top: 5.56vw;
    margin-bottom: -15.17vw;
}
.mobile_cat_header ul li {
    flex-grow: 1;
    flex-shrink: 1;
}
.table.w-100 {
    width: 90vw !important;
}
.order_box_title h3 {
    font-size: 2.72vw;
}
.order_box_img {
    width: 33vw;
    height: 29vw;
}

.order_box_img img {
    width: 23vw;
}
.product_save {
    width: 4.97vw;
    height: 1.97vw;
}
.product_save img {
    width: 5.45vw !important;
}
.cart_detail {
    flex-direction: column;
}
.cart_img {
    width: 26.9vw;
    height: 30.55vw;
}
.cart_img img {
 
  width: 24vw;
}
.cart_price {
    height: 6.95vw;
    margin-top: 2vw;
}
.cart_price h5 {
    font-size: 2.72vw;
}
.cart_info2_bt2 img {
    width: 3.65vw;
    margin-right: 1.35vw;
}
.cart_info2_bt1 img {
    width: 4.75vw;
    margin-right: 1.35vw;
}
.cart_info2_bt1 {
    width: 23.65vw;
    height: 7vw;
    font-size: 2.72vw;
}

.cart_info2_bt2 {
    width: 27.8vw;
    height: 6.85vw;
    font-size: 2.725vw;
}

.cart_info input[type=file]::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #01AEF0;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background .2s ease-in-out;
  width: 25.54vw;
  height: 7.6vw;
  border-radius: 40px;
  background: #01AEF0;
  color: #FFF;
  font-family: Poppins;
  font-size: 2.72vw;
  font-weight: 500;
  border: none;
  box-shadow: 0px 0px 8px 3px rgb(0 0 0 / 5%);
}
.checkout_wrapper {
    display: flex;
    column-gap: 3.08vw;
    flex-direction: column;
}
.checkout_contact_form {
    width: 90vw;
}
.chekout_contact_icon img {
    width: 9.08vw;
}
.chekout_contact_title h3 {
    font-size: 3.2vw;
}
.checkout_contact_form, .checkout_shipping_form, .checkout_payment_form {
    padding: 4.3vw 2.08vw 0 5.97vw;
}
.checkout_contact_body_title h3 {
    font-size: 3.2vw;
}
.ml-1{
  font-size: 3.2vw;
}
.checkout_shipping_form {
    width: 90vw;
}
.shipping_address_item button {
    border-radius: 10px;
    background: rgba(1, 174, 240, 0.20);
    border: none;
    width: 41.13vw;
    height: 9.6vw;
    color: #000;
}
.chekout_contact_change button {
    border-radius: 10px;
    border: none;
    width: 31.13vw;
    padding: 0vw 1vw;
    height: 9.6vw;
}
.chekout_contact_title {
    margin-right: 0;
}
.checkout_contact_form, .checkout_shipping_form, .checkout_payment_form {
    padding: 4.3vw 2.08vw 4vw 5.97vw;
}
.checkout_order_summary .cart_list {
    width: 90vw;
}
.checkout_order_summary .cart_item {
    width: 90vw;
}
.cart_img.checkout {
    height: 37vw;
}
.total-summary h4 {
    color: #252525;
    font-size: 4.5vw;
    font-weight: 500;
}
.discount_code input {
    width: 66.89vw;
    height: 8.6vw;
}
.discount_code button {
    width: 21.13vw;
    height: 8.6vw;
    font-size: 3.2vw;
}
.cart_total.checkout {
    width: 90vw;
}
.giv_error {
    font-size: 2.72vw;
}
.explore-nav {
    width: 100%;
    overflow: scroll;
}
ul.explore-nav-menu {
    overflow: scroll;
        padding: 0vw 2vw;
}
.explore-nav-menu li {
    font-size: 3.2vw;
}
.explore-nav-menu li img {
    width: 4.93vw;
    height: 4.93vw;
    margin-right: 1.52vw;
}
.order_list_table td {
    font-size: 2.72vw;
}
.order_list_table td button {
    font-size: 2.72vw;
    font-weight: 500;
    font-family: poppins;
}
.order_list_table td button {
    width: 19vw;
    border-radius: 40px;
    height: 7vw;
    margin-bottom: 2vw;
}
.me-2 {
    margin-right: 0;
}
.order_detail_wrapper.order {
    width: 90vw;
}
.cart_total.order {
    margin: 0;
    width: 90vw !important;
}
.order_detail_wrapper.order p{
  font-size: 2.72vw;
}
.order_detail_wrapper.order h4{
  font-size: 3.2vw;
}
.cart_color_size span {
    font-size: 2.72vw;
    margin: 2vw;
}
.cart_detail.order .cart_title h3 {
    font-size: 3.2vw;
}
.faq_wrapper .MuiAccordionSummary-expandIconWrapper {
    font-size: 5vw !important;
    height: 7vw !important;
}
input[placeholder="Search"] {
    border-color: #fff;
    padding: 2.5vw 1vw;
    color: #555 !important;
}
input[placeholder="Search"]::placeholder{
  color: #555 !important;
}
.header-ul.MuiBox-root.css-j7qwjs{
  flex-direction: row;
    column-gap: 3vw;
}
nav .cate-header-ul.MuiBox-root.css-70qvj9 .sub_cate_menu_ a {
  color: #555 !important;
  font-weight: 500 !important;
}
.all_products_1.hover_open_menu.MuiBox-root.css-70qvj9 li a, .all_products_2.hover_open_menu_1.MuiBox-root.css-70qvj9 li{
  color: #555 !important;
}

.all_products_1{
  margin-top: 0 !important;
  visibility: visible !important;
}
.product_detail_content ul{
  font-size: 2.83vw !important;
}
.product_detail_content table{
  margin-top: 5vw;
}
.product_detail_content table th, .product_detail_content table td{
      font-family: Poppins !important;
      font-size: 3.2vw;

}
.prev_btn {
    left: 1vw;
}
.next_btn {
    right: 1vw;
}

ul.explore-nav-menu {
width: 189vw;
padding: 1vw 3vw;
}
.explore-nav-menu li {
    padding-top: 1.52vw;
    padding-bottom: 1.52vw;
    padding-left: 4.04vw;
    padding-right: 3.04vw;
}
.home_page_slider .next, .home_page_slider .prev {
    cursor: pointer;
    position: absolute;
    top: 42%;
    /* transform: translateY(-50%); */
    font-size: 20px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    padding: 4px 13px;
    border-radius: 50%;
}
.home_page_slider img {
    width: 100%;
    object-fit: cover;
    height: 100%;
    animation: slideText 0.5s linear;
    /* transform: translateX(0%); */
}
.category_box {
    width: 43.77vw;
}
.category_box_img img {
    width: 34.2vw;
}
.category_box_title h3 {
    font-size: 3.2vw;
}
p.sku{
  font-size: 2.83vw !important;
}
.header_signup_button{
  font-size: 3.23vw !important;
} 
}
.required::after{
  content: '*';
  color: red;
  margin-left: 3px;
}
.account_menu .active li  {
  border-bottom: 2px solid #01AEF0; 
}
.account_menu .active li button{
  color: #01AEF0 !important;

}
.search-results li a{
  width: 17.34vw;
  display: block;
}
.sub_cate_menu_.p a{
  color: #555 !important;
}
.artwork_image{
  margin-right: 1.25vw;
}

.cont {
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative;


}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for transparency */
  z-index: 1000;
}
.side_bar {
  height: 100%;
  width: 24%;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: rgb(253, 253, 253);
  overflow-x: hidden;
  transition: 0.5s;
  padding: 30px;
  z-index: 1001;

}
.empty_cart_div {
  height: 500px;
  gap: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.empty_cart_div h3 {
  font-size: 21px;
  font-weight: 600;
  color: #414042; 
}
.artwork_icons {
  display: flex;
  gap: 30px;
}
.artworks {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.artworks p {
  font-size: 14px;
  color: black;
  font-weight: 500;
  margin-top: 4px;
}

#openBtn {
  padding: 10px;
  cursor: pointer;
}

#closeBtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 30px;
  margin-left: 50px;
  color: #000;
  cursor: pointer;
}
.item {
  
  min-height: 80px;
  width: 90%;
  border-bottom: 1px solid rgba(37, 37, 37, 0.15);
  margin-block: 20px;
  background-color: #fff;
  margin-left: 10px;
 
}
.img_section {
  display: flex;
  gap: 5px;
}

.label_wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.top{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.top p {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #414042;
  margin-right: 20px;
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;

}
.rmv {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #808285;
  position: absolute;
    top: 0;
    right: 0;
  margin-bottom: 5px;
  cursor: pointer;
}
 .middle{
  margin-top: 10px;
 }
.middle p {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #808285;
  margin-bottom: 5px;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.last {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

}
.last h2 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #808285;
  margin-bottom: 1px;
}
.last span {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #808285;
  margin-bottom: 1px;
}
.line {
  border-bottom: 1px solid #ccc;

}
.last_part {
  margin-top: 50px;

}
.qty_total {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: .96px;
  text-align: left;
  color: #808285;
  margin-bottom: 10px;
 
}
.total {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-block: 10px;
}
.total p{
  font-weight: 500;
  line-height: 1.33;
  font-size: 12px;
  letter-spacing: .96px;
  text-align: left;
  color: #414042;
  text-transform: uppercase;
}
.total p span {
  font-size: 12px;
  font-weight: 400;
  margin-left: 5px;
  font-style: italic;
  color: #414042;
  line-height: 1.33;
  letter-spacing: .96px;

}
.button_div{
  display: flex;
  justify-content: center;
}

.go_to_cart_button {
  width: 100%;
  letter-spacing: 2px;
  margin-top: 10px;
  margin-bottom: 30px;
  height: 37px;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.2px;
  padding-inline: 40px;
  padding-block: 10px;
  background-color: #01AEF0;
  border:1px solid #01AEF0;
  color: #fff;
}
.slider-loader {
  height: 356px;
  width: 100%;
  background-color: hsl(0, 0%, 96%);
  border: none;
}
.slider_section {
  height: 500px;
  width: 100%;
}
.button_transparent .title_svg{
  width: 50px;
}
@media screen and (min-device-width: 2561px) and (max-device-width: 4000px) { 
  .slider_section {
    height: 1000px;
  }
  .button_transparent .title_svg{
    width: 100px;
  }
}
@media screen and (min-device-width: 1980px) and (max-device-width: 2560px) { 
  .slider_section {
    height: 650px;
  }
  .button_transparent .title_svg{
    width: 60px;
  }
}
@media screen and (min-device-width: 1281px) and (max-device-width: 1680px) { 
  .slider_section {
    height: 356px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 1141px) and (max-device-width: 1280px) { 
  .slider_section {
    height: 300px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 980px) and (max-device-width: 1140px) { 
  .slider_section {
    height: 280px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 900px) and (max-device-width: 979px) { 
  .slider_section {
    height: 250px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 800px) { 
  .slider_section {
    height: 210px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 561px) and (max-device-width: 768px) { 
  .slider_section {
    height: 210px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 441px) and (max-device-width: 560px) { 
  .slider_section {
    height: 150px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

@media screen and (min-device-width: 361px) and (max-device-width: 440px) { 
  .slider_section {
    height: 150px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}
@media only screen and (max-width: 320px) {
  .slider_section {
    height: 110px;
  }
  .button_transparent .title_svg{
    width: 35px;
  }
}

.container-confirm {
  flex-direction: column;
  position: fixed;
  background-color: #f9f9f9;
  width: 430px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  border-radius: 10px;
  padding: 15px;
  z-index: 1005;

}

.confirmation-text {
  display: flex;
  color: black;
  margin: 8px 0px 15px;
  line-height: 1.3rem;
  font-weight: 500;
  font-size: 14px;
}

.button-container {
  display: flex;
  margin-top: auto;
  justify-content: end;
  gap: 15px;

}

.confirmation-button, delete-button {
  display: inline-flex;
  background-color:#dfdfdf ;
  color: black;
  padding: 10px 12px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
}

.cancel-button {
  background-color: #01AEF0;
  display: inline-flex;
  color: #fff;
  padding: 10px 12px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 400;
}


.backdrop-alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); 
  z-index: 1003;
}
.site-name {
  color: black;
  line-height: 2rem;
  font-weight: 500;
  font-size: 13px;
}
.site-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* .slider.slide {
  opacity: 0 !important;
  /* transition: 0.5s  !important; 
  display: block !important;
  position: absolute !important;;
}

.slider.active {
  opacity: 1 !important;
} */

.slider {
  display: block !important;
  position: absolute;
  width: 100% !important;
}

.slider:not(.active) {
  opacity: 0; /* Hide non-current slides */
  display: none !important;
}

.discover-area-wrapper .slide-title,.arrivals_wrapper .slide-title {
	margin-bottom: 50px;
	margin-top: 30px;
}

.discover-area-wrapper .discover_heading,.arrivals_wrapper .discover_heading{
  display: block;
}
.discover-area-wrapper .discover_heading h2{
 float: left;
  margin: 0px 20px 0px 0px;
}
.arrivals_wrapper .discover_heading h2{
  font-size: 1.87vw;
  float: left;
  margin: 0px 20px 0px 0px;
}

@media (max-width: 520px) {
  .arrivals_wrapper .discover_heading h2{
    font-size: 4.5vw;
  }
  .slider_section {
	height: 230px;
}
.discover-area-wrapper .slide-title, .arrivals_wrapper .slide-title {
	margin-bottom: 20px;
	margin-top: 0px;
}
}

.whole_area_md {
  display: flex;
  width: 100%;
  position: relative;
}
.backdrop_md {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  z-index: 1000;
}
.modal_container_md {
  flex-direction: column;
  position: fixed;
  width: 57vw;
  top:50%;
  border-radius: 10px;
  max-height: 90vh;
  overflow-y: scroll;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1005;
}
.modal_wrapper {
  height: 100%;
  width: 100%;
  padding-inline: 24px;
    padding-block: 32px;
  position: relative;
}
.modal_closer{
  position: absolute;
  top: 3px;
font-size: 24px;
  cursor: pointer;
  right: 3px;
}
.modal_image{
  float: right;
  max-width: 264px;
}
.modal_title_wrapper{
  display: block;
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(243, 244, 246);
  
}
.modal_txt{
  font-size: .875rem;
  line-height: 1.25rem;
 
}
.modal_list_wrapper{
  list-style: disc;
  padding-left: 20px;
}
.empty_crt{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.empty_crt p{
margin-bottom: 15px;
}
.empty_crt  button {
  margin-top: 1.71vw;
    margin-bottom: 1.56vw;
    width: 23.54vw;
    height: 2.60vw;
    border-radius: 40px;
    background: #01AEF0;
    color: #FFF;
    font-family: Poppins;
    font-size: 0.83vw;
    font-weight: 500;
    border: none;
    box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.05);
}
@media  (max-width: 520px) {
  .modal_container_md {
     width: 80vw;
     top: 60%;
     max-height: 78vh;
     overflow-y: scroll;
    
  }
  .modal_wrapper{
    padding: 18px;
  }
  .modal_closer{
    top: 0px;
    font-size: 26px;
    right: 11px;
  }
  
}

